<template>
  <div
    class="auth__form__content"
    id="auth-login"
    v-if="getVueAppUseRegister == 'yes'"
  >
    <form v-on:submit="register">
      <div v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="(error, key) in errors" :key="key">
            {{ error }}
          </li>
        </ul>
      </div>

      <h2 class="is-size-2">Register New User</h2>
      <b-field label="Email">
        <b-input
          v-model="email"
          placeholder="e.g: admin@roketin.com"
          required
          size="is-size-5"
        ></b-input>
      </b-field>
      <b-field label="Password">
        <b-input
          v-model="password"
          type="password"
          placeholder="password"
          required
          size="is-size-5"
          password-reveal
        ></b-input>
      </b-field>
      <b-field label="Confirm Password">
        <b-input
          type="password"
          placeholder="password_confirmation"
          v-model="passwordConfirmation"
          required
          size="is-size-5"
          password-reveal
        ></b-input>
      </b-field>
      <b-field class="is-flex is-justify-content-center" required>
        <b-button native-type="submit" class="is-size-5 mb-2 mt-2" expanded>
          Register
        </b-button>
      </b-field>
    </form>
    <div class="auth__form__footer">
      <div class="is-size-5">
        {{ `Don't want to register new user? ` }}
        <span>
          <router-link
            to="login"
            class="is-link is-size-5 has-text-weight-semi-bold"
          >
            Login
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { showToast } from '@/services/util'
export default {
  data() {
    return {
      errors: [],
      email: null,
      password: null,
      passwordConfirmation: null,
    }
  },
  computed: {
    getVueAppUseRegister() {
      return process.env.VUE_APP_USE_REGISTER
    },
  },
  methods: {
    register: function (e) {
      e.preventDefault()
      if (this.password !== this.passwordConfirmation) {
        this.errors.push('Password Not Equal')
      } else {
        let email = this.email
        let password = this.password
        let redirect = '/auth/login'
        this.$store
          .dispatch('auth/register', { email, password, redirect })
          .then(() => {
            console.log()
            showToast(
              'Register Success, Email has been sent. Please activate Your account via email',
              'is-success',
              'is-bottom'
            )
          })
          .catch((error) => {
            console.log(error)
            showToast(error, 'is-danger', 'is-bottom')
          })
      }
    },
  },
}
</script>
